@charset 'utf-8';

@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-display: swap;
	src: local('Pretendard Bold'), url('https://dx6sd8rl35cx.cloudfront.net/assets/font/Pretendard-Bold.woff2') format('woff2'), url('https://dx6sd8rl35cx.cloudfront.net/assets/font/Pretendard-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 500;
	font-display: swap;
	src: local('Pretendard Medium'), url('https://dx6sd8rl35cx.cloudfront.net/assets/font/Pretendard-Medium.woff2') format('woff2'), url('https://dx6sd8rl35cx.cloudfront.net/assets/font/Pretendard-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: swap;
	src: local('Pretendard Regular'), url('https://dx6sd8rl35cx.cloudfront.net/assets./font/Pretendard-Regular.woff2') format('woff2'), url('https://dx6sd8rl35cx.cloudfront.net/assets/font/Pretendard-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 300;
	font-display: swap;
	src: local('Pretendard Light'), url('https://dx6sd8rl35cx.cloudfront.net/assets./font/Pretendard-Light.woff2') format('woff2'), url('https://dx6sd8rl35cx.cloudfront.net/assets/font/Pretendard-Light.woff') format('woff');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 100;
	font-display: swap;
	src: local('Pretendard Thin'), url('https://dx6sd8rl35cx.cloudfront.net/assets/font/Pretendard-Thin.woff2') format('woff2'), url('https://dx6sd8rl35cx.cloudfront.net/assets/font/Pretendard-Thin.woff') format('woff');
}