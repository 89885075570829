/*******************************************************************
	Layout
********************************************************************/
#wrap{
	position: relative;
	width: 100%;
	min-height: 100%;
}
#header{
	z-index: 500;
	position: sticky;
	top: 0;
	width: 100%;
	background: $white;
	.header-wrap{
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 1160px;
		height: 80px;
		margin: 0 auto;
		.logo{
			flex-shrink: 0;
			height: 24px;
			& > a{
				img{
					width: auto;
					height: 100%;
				}
			}
		}
		.nav{
			.gnb{
				display: flex;
				align-items: center;
				& > li{
					& > a{
						display: block;
						padding: 0 50px;
						@include font(16px, $black-9);
					}
					&:hover,
					&.active{
						& > a{
							color: $primary;
							font-weight: $fw-bold;
						}
					}
				}
			}
			.btn-login{
				display: none;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 48px;
				margin-top: 60px;
				border-radius: 24px;
				background: $primary;
				@include font(16px, $white);
				font-weight: $fw-bold;
			}
		}
		.info-menu{
			display: flex;
			align-items: center;
			.login-info{
				margin-right: 40px;
				& > a{
					@include font(16px, $black-1);
					&:hover{
						color: $primary;
						font-weight: $fw-bold;
					}
				}
			}
			.language{
				position: relative;
				width: 24px;
				height: 24px;
				&-btn{
					width: 100%;
					height: 100%;
					background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/btn/btn_language.svg") no-repeat 0 0;
					background-size: 100%;
				}
				&-list{
					z-index: 10;
					@include center(horizontal);
					top: 35px;
					display: none;
					padding: 16px;
					border: 1px solid $border-1;
					border-radius: 8px;
					background: $white;
					& > li{
						& > a{
							display: block;
							width: 40px;
							height: 32px;
							line-height: 32px;
							@include font(16px, $black-9);
							text-align: center;
						}
						&:hover,
						&.active{
							& > a{
								color: $primary;
								font-weight: $fw-bold;
							}
						}
					}
				}
				&:hover{
					.language-btn{
						background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/btn/btn_language_ov.svg") no-repeat 0 0;
						background-size: 100%;
					}
				}
				&.active{
					.language-btn{
						background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/btn/btn_language_ov.svg") no-repeat 0 0;
						background-size: 100%;
					}
					.language-list{
						display: block;
					}
				}
			}
			// 모바일 메뉴
			.btn-drawer{
				display: none;
				width: 24px;
				height: 24px;
				margin-left: 12px;
				background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/btn/btn_menu.svg") no-repeat 0 0;
				background-size: 100%;
				&.active{
					background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/btn/btn_close.svg") no-repeat 0 0;
					background-size: 100%;
				}
			}
		}
	}

	@media all and (max-width:1200px){
		.header-wrap{
			max-width: 960px;
		}
	}
	@media all and (max-width:1023px){
		.header-wrap{
			height: 60px;
			padding: 0 20px;
			.logo{
				height: 20px;
			}
			.nav{
				@include center(def);
				top: 40px;
				display: none;
				width: 100%;
				min-height: -webkit-fill-available;
				min-height: calc(var(--vh, 1vh) * 100);
				padding: 20px;
				background: $white;
				.gnb{
					flex-direction: column;
					width: 100%;
					& > li{
						width: 100%;
						& > a{
							width: 100%;
							padding: 20px 0;
							@include font(16px, $black-1);
							border-bottom: 1px solid $border-1;
						}
						& + li{
							margin-left: 0;
						}
						&:hover{
							color: $black-1;
						}
					}
				}
				.btn-login{
					display: inline-flex;
				}
				&.active{
					display: block;
				}
			}
			.info-menu{
				.login-info{
					display: none;
				}
				.language{
					&-list{
						& > li{
							&:hover{
								& > a{
									color: $black-9;
								}
							}
							&.active{
								& > a{
									color: $primary;
								}
							}
						}
					}
					&:hover{
						.language-btn{
							background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/btn/btn_language.svg") no-repeat 0 0;
							background-size: 100%;
						}
					}
					&.active{
						.language-btn{
							background: url("https://dx6sd8rl35cx.cloudfront.net/assets./images/btn/btn_language_ov.svg") no-repeat 0 0;
							background-size: 100%;
						}
					}
				}
				// 모바일 메뉴
				.btn-drawer{
					display: block;
				}
			}
		}
	}
}
#container{	
	padding-bottom: 200px;
	@media all and (max-width:1023px){
		padding-bottom: 260px;
	}
}
#footer{
	@include center(self,null,null,0,0);
	width: 100%;
	background: $white;
	.footer-wrap{
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: 1160px;
		padding: 60px 0;
		height: 200px;
		margin: 0 auto;
		.fnb{
			.foot-logo{
				display: block;
				height: 24px;
				img{
					width: auto;
					height: 100%;
				}
			}
			.fnb-list{
				display: flex;
				align-items: center;
				margin-top: 20px;
				& > li{
					display: flex;
					align-items: center;
					@include font(14px, $gray-c);
					line-height: 1.4;
					& + li{
						padding-left: 10px;
						&::before{
							content: '';
							width: 1px;
							height: 12px;
							margin-right: 10px;
							background: $gray-c;
						}
					}
				}
			}
			.copyright{
				margin-top: 5px;
				@include font(14px, $gray-c);
			}
		}
	}

	@media all and (max-width:1200px){
		.footer-wrap{
			max-width: 960px;
			.fnb{
				.foot-logo{
					height: 20px;
				}
				.fnb-list{
					& > li{
						@include font(14px);
					}
				}
			}
		}
	}
	@media all and (max-width:1023px){
		.footer-wrap{
			display: block;
			padding: 30px 20px 40px;
			height: 260px;
			.fnb{
				.foot-logo{
					height: 16px;
				}
				.fnb-list{
					display: block;
					& > li{
						display: block;
						@include font(12px);
						& + li{
							padding-left: 0;
							margin-top: 5px;
							&::before{
								content: none;
							}
						}
					}
				}
				.copyright{
					margin-top: 10px;
					@include font(12px);
				}
			}
		}
	}
}

.content{
	width: 100%;
	max-width: 1160px;
	margin: 0 auto;
	@media all and (max-width:1200px){
		max-width: 960px;
	}
}

// main title
.main-tit-set{
	.tit{
		@include font(36px, $black-1, 48px);
		font-weight: $fw-bold;
	}
	&.type2{
		.tit{
			@include font(48px, $black-1, 60px);
			font-weight: $fw-bold;
		}
	}
	@media all and (max-width:1200px){
		.tit{
			@include font(30px, null, 42px);
		}
		&.type2{
			.tit{
				@include font(38px, null, 50px);
			}
		}
	}
	@media all and (max-width:1023px){
		.tit{
			@include font(24px,null,32px);
			word-break: keep-all;
			br{
				display: none;
			}
			&.block{
				br{
					display: block;
				}
			}
		}
		&.type2{
			.tit{
				@include font(28px,null,36px);
				word-break: keep-all;
			}
		}
	}
}

// sub title
.sub-tit-set{
	display: flex;
	align-items: center;
	justify-content: space-between;
	.tit{
		@include font(24px, $black-1);
		font-weight: $fw-bold;
	}
	.txt{
		width: 50%;
		@include font(16px, $black-6);
		line-height: 1.5;
	}
	&.type2{
		display: block;
		.tit{
			@include font(30px, $black-1);
			font-weight: $fw-bold;
			line-height: 1.4;
		}
		.s-tit{
			margin-top: 10px;
			@include font(16px, $black-9);
			line-height: 1.4;
		}
	}
	&.type3{
		align-items: flex-start;
		.tit{
			@include font(30px, $black-1, 40px);
			font-weight: $fw-bold;
		}
		.txt{
			@include font(16px, $black-9, 24px);
			word-break: keep-all;
		}
	}

	@media all and (max-width:1200px){
		.tit{
			@include font(22px);
		}
		.txt{
			width: 55%;
			@include font(14px, null, 22px);
		}
		&.type2{
			.tit{
				@include font(26px, null, 36px);
			}
			.s-tit{
				@include font(14px);
			}
		}
		&.type3{
			.tit{
				@include font(26px, null, 36px);
			}
			.txt{
				@include font(14px, null, 22px);
			}
		}
	}
	@media all and (max-width:1023px){
		display: block;
		.tit{
			@include font(20px);
			word-break: keep-all;
		}
		.txt{
			width: 100%;
			margin-top: 10px;
			@include font(14px);
			word-break: keep-all;
		}
		&.type2{
			.tit{
				@include font(20px,null,26px);
				word-break: keep-all;
			}
			.s-tit{
				@include font(14px,null,20px);
				word-break: keep-all;
			}
		}
		&.type3{
			display: block;
			.tit{
				@include font(20px,null,26px);
				word-break: keep-all;
			}
			.txt{
				margin-top: 10px;
				@include font(14px,null,20px);
				word-break: keep-all;
				br{
					display: none;
				}
			}
		}
	}
}

section{
	width: 100%;
	padding: 120px 0;
	background: $white;
	&.bg-type2{
		background: $bg-f7;
	}
	&.inner{
		padding-bottom: 0;
	}
	// info-box
	.info-box{
		.tit-set{
			.tit{
				@include font(30px, $black-1, 40px);
				font-weight: $fw-bold;
			}
			.s-tit{
				margin-top: 10px;
				@include font(16px, $black-9, 24px);
			}
			.info-list{
				margin-top: 60px;
				& > li{
					& + li{
						margin-top: 40px;
					}
					.label{
						@include font(24px, $black-1);
						font-weight: $fw-bold;
					}
					.txt{
						margin-top: 15px;
						@include font(16px, $black-9, 24px);
						word-break: keep-all;
					}
				}
			}
		}
		&.main{
			.tit-set{
				.tit{
					@include font(48px,null,60px);
				}
				.s-tit{
					margin-top: 30px;
					@include font(20px,null,30px);
				}
			}
			.btn-round.primary{
				margin-top: 60px;
			}
			.mark-list{
				display: flex;
				margin-top: 40px;
				& > li{
					position: relative;
					height: 120px;
					border-radius: 10px;
					box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
					img{
						width: auto;
						height: 100%;
					}
					.dim{
						@include center(def);
						width: 100%;
						height: 100%;
						border-radius: 10px;
						background: rgba(17, 17, 17, 0.5);
						opacity: 0;
						transition: all 0.3s;
						.txt{
							@include center(both);
							width: 100%;
							@include font(14px, $white);
							text-align: center;
							line-height: 1.4;
						}
					}
					& + li{
						margin-left: 30px;
					}
					&:hover{
						.dim{
							opacity: 1;
						}
					}
				}
			}
		}
	}

	@media all and (max-width:1200px){
		padding: 50px 0;
		// info-box
		.info-box{
			.tit-set{
				.tit{
					@include font(26px, null, 36px);
				}
				.s-tit{
					@include font(14px, null, 22px);
				}
				.info-list{
					margin-top: 40px;
					& > li{
						& + li{
							margin-top: 20px;
						}
						.label{
							@include font(20px);
						}
						.txt{
							margin-top: 10px;
							@include font(14px, null, 22px);
							br{
								display: none;
							}
						}
					}
				}
			}
			&.main{
				.tit-set{
					.tit{
						@include font(38px,null,50px);
					}
					.s-tit{
						margin-top: 20px;
						@include font(16px,null,24px);
					}
				}
				.btn-round.primary{
					margin-top: 40px;
				}
				.mark-list{
					margin-top: 30px;
					& > li{
						height: 90px;
						.dim{
							.txt{
								@include font(12px);
							}
						}
						& + li{
							margin-left: 20px;
						}
					}
				}
			}
		}
	}
	@media all and (max-width:1023px){
		padding: 45px 20px;
		&.inner{
			padding-bottom: 40px;
		}
		.info-box{
			width: 100%;
			.tit-set{
				.tit{
					@include font(20px,null,26px);
					word-break: keep-all;
				}
				.s-tit{
					margin-top: 10px;
					@include font(14px,null,20px);
					word-break: keep-all;
					br{
						display: none;
					}
				}
				.info-list{
					margin-top: 30px;
					& > li{
						& + li{
							margin-top: 20px;
						}
						.label{
							@include font(18px,null,26px);
							word-break: keep-all;
						}
						.txt{
							margin-top: 10px;
							@include font(14px,null,20px);
							word-break: keep-all;
						}
					}
				}
			}
			&.main{
				.tit-set{
					.tit{
						@include font(28px,null,36px);
						word-break: keep-all;
					}
					.s-tit{
						margin-top: 20px;
						@include font(16px,null,24px);
						word-break: keep-all;
						br{
							display: none;
						}
						
					}
				}
				.btn-round.primary{
					margin-top: 30px;
				}
				.mark-list{
					margin-top: 20px;
					& > li{
						height: 100px;
						.dim{
							.txt{
								@include font(12px);
							}
						}
						& + li{
							margin-left: 15px;
						}
					}
				}
			}
		}
	}
}

.row-set{
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media all and (max-width:1023px){
		display: block;
	}
}

.swiper-pagination{
	.swiper-pagination-bullet{
		width: 12px;
		height: 12px;
		background: $bg-e7;
		opacity: 1;
		margin: 0 8px !important;
		&-active{
			background: $black-1;
		}
	}
	@media all and (max-width:1023px){
		.swiper-pagination-bullet{
			width: 8px;
			height: 8px;
			margin: 0 5px !important;
		}
	}
}

/*******************************************************************
	main (홈)
********************************************************************/
.main{
	section{
		&.sec1{
			padding: 80px 0 30px;
			.row-set{
				.img-box{
					width: 480px;
				}
			}
		}
		&.sec2{
			.swiper-service{
				.service-list{
					display: flex;
					& > li{
						padding: 40px;
						border-radius: 20px;
						background: $bg-f7;
						&::before{
							content: '';
							display: block;
							width: 60px;
							height: 60px;
						}
						&:nth-child(1){
							&::before{
								background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/icon/icon_service1.svg") no-repeat 0 0;
								background-size: 100%;
							}
						}
						&:nth-child(2){
							&::before{
								background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/icon/icon_service2.svg") no-repeat 0 0;
								background-size: 100%;
							}
						}
						&:nth-child(3){
							&::before{
								background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/icon/icon_service3.svg") no-repeat 0 0;
								background-size: 100%;
							}
						}
						.txt-box{
							margin-top: 90px;
							.tit{
								@include font(24px, $black-1);
								font-weight: $fw-bold;
								line-height: 1.4;
							}
							.txt{
								margin-top: 15px;
								@include font(16px, $black-9);
								line-height: 1.6;
							}
						}
					}
				}
			}
		}
		&.sec3{
			.tech-box{
				display: flex;
				align-items: center;
				overflow: hidden;
				margin-top: 60px;
				border-radius: 20px;
				border: 1px solid $border-1;
				.tech-graph{
					position: relative;
					width: 580px;
					padding: 40px;
					background: $black-3;
					.anim-box{
						position: relative;
						width: 100%;
						.anim{
							@include center(def);
							opacity: 0;
							width: 100%;
							height: 100%;
							svg{
								width: 100%;
								height: 100%;
								object-fit: contain;
							}
							&.def{
								position: static;
								opacity: 1;
							}
							&.step1,
							&.step2,
							&.step3{
								top: 5%;
							}
							&.dot1,
							&.dot2,
							&.dot3{
								z-index: 2;
								top: -5%;
							}
							&.step4{
								width: 100%;
								height: 100%;
								.hidden-box{
									overflow: hidden;
									@include center(def);
									width: 0;
									height: 100%;
									margin-left: 0;
									&::after{
										content: '';
										@include center(self, 13%,0,null,null);
										width: 0.5px;
										height: 78%;
										background: $black-6;
									}
									.hidden-txt{
										z-index: 3;
										@include center(self, 14%,9px,null,null);
										@include font(13px, $primary);
										line-height: 1.2;
										font-weight: $fw-bold;
										text-align: right;
									}
								}
							}
						}
					}
				}
				.txt-box{
					padding: 0 80px;
					.tit{
						@include font(24px, $black-1, 36px);
						font-weight: $fw-bold;
					}
					.info-list{
						margin-top: 12px;
						& > li{
							&::before{
								content: '·';
								margin: -3px 8px;
								@include font(18px);
								font-weight: $fw-bold;
							}
							@include font(16px, $black-9, 24px);
						}
					}
					.btn-round{
						margin-top: 40px;
					}
				}
			}
		}
		&.sec4{
			.solution-list{
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 40px;
				margin-top: 60px;
				& > li{
					.link-box{
						display: block;
						&::before{
							content: '';
							display: block;
							width: 100px;
							height: 100px;
						}
						&::after{
							content: '';
							display: block;
							width: 36px;
							height: 36px;
							margin-top: 10px;
							background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/icon/icon_arrow.svg") no-repeat 0 0;
							background-size: 100%;
						}
						.txt-box{
							margin-top: 20px;
							.tit{
								@include font(24px, $black-1, 36px);
								font-weight: $fw-bold;
								br{
									display: none;
								}
							}
							.info-list{
								margin-top: 10px;
								& > li{
									display: flex;
									@include font(16px, $black-9, 24px);
									&::before{
										content: '·';
										margin: -3px 8px;
										@include font(18px);
										font-weight: $fw-bold;
									}
								}
							}
						}
					}
					&:nth-child(1){
						.link-box{
							&::before{
								background: url("https://dx6sd8rl35cx.cloudfront.net/assets./images/icon/icon_solution1.png") no-repeat 0 0;
								background-size: 100%;
							}
						}
					}
					&:nth-child(2){
						.link-box{
							&::before{
								background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/icon/icon_solution2.png") no-repeat 0 0;
								background-size: 100%;
							}
						}
					}
					&:nth-child(3){
						.link-box{
							&::before{
								background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/icon/icon_solution3.png") no-repeat 0 0;
								background-size: 100%;
							}
						}
					}
					&:nth-child(4){
						.link-box{
							&::before{
								background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/icon/icon_solution4.png") no-repeat 0 0;
								background-size: 100%;
							}
						}
					}
					
				}
			}
		}
		&.sec5{
			.swiper-partner{
				.partner{
					.swiper-wrapper{
						.swiper-slide{
							padding: 20px 5px;
							.swiper-list{
								display: grid;
								grid-template-columns: repeat(6,1fr);
								gap: 30px 78px;
								& > li{
									.img-set{
										position: relative;
										border-radius: 50%;
										box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
										.dim{
											@include center(def);
											width: 100%;
											height: 100%;
											border-radius: 50%;
											background: rgba(17, 17, 17, 0.5);
											opacity: 0;
											transition: opacity 0.3s;
											.txt-set{
												@include center(both);
												width: 100%;
												padding: 0 8px;
												@include font(12px, $white);
												text-align: center;
												line-height: 1.4;
												font-weight: $fw-bold;
												.tit{
													@include font(15px, $white);
													line-height: 1.2;
													word-break: keep-all;
												}
												.txt{
													word-break: keep-all;
												}
											}
										}
									}
									&:hover{
										.dim{
											opacity: 1;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&.sec6{
			.swiper-example{
				.example{
					.swiper-wrapper{
						.swiper-slide{
							.example-box{
								display: flex;
								align-items: center;
								justify-content: space-between;
								width: 100%;
								border-radius: 20px;
								background: $bg-f7;
								.img-set{
									width: 560px;
								}
								.txt-box{
									width: 50%;
									padding: 0 50px 0 20px;
									.tit{
										@include font(30px, $black-1, 40px);
										font-weight: $fw-bold;
										strong{
											color: $primary;
										}
									}
									.info-box{
										display: flex;
										align-items: center;
										margin-top: 40px;
										.thumb{
											flex-shrink: 0;
											width: 60px;
											height: 60px;
											margin-right: 16px;
											border-radius: 10px;
											box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
										}
										.txt-set{
											.name-box{
												display: flex;
												align-items: center;
												@include font(20px, $black-1);
												font-weight: $fw-bold;
												& > div{
													& + div{
														display: flex;
														align-items: center;
														padding-left: 10px;
														&::before{
															content: '';
															width: 2px;
															height: 18px;
															margin-right: 10px;
															background: $black-1;
														}
													}
												}
											}
											.company{
												margin-top: 5px;
												@include font(16px, $black-9);
											}
										}
									}
								}
							}
						}
					}
				}
			}	
		}
		.swiper-wrap{
			position: relative;
			padding: 50px 0 70px;
			&.swiper-service{
				padding-bottom: 0;
			}
			@media all and (max-width:1200px){
				padding: 40px 0 60px;
			}
			@media all and (max-width:1023px){
				padding: 30px 0 40px;
				&.swiper-service{
					padding-bottom: 50px;
				}
			}
		}
	}
	.banner-area{
		height: 240px;
		background: $primary;
		.content{
			height: 100%;
			.banner-set{
				display: flex;
				align-items: center;
				height: 100%;
				justify-content: space-between;
				.tit{
					@include font(30px, $white);
					font-weight: $fw-bold;
					line-height: 1.4;
				}
			}
		}
	}
	
	@media all and (max-width:1200px){
		section{
			&.sec1{
				padding: 40px 20px 20px;
				.row-set{
					.img-box{
						width: 450px;
					}
				}
			}
			&.sec2{
				.swiper-service{
					.service-list{
						& > li{
							padding: 30px;
							&::before{
								width: 50px;
								height: 50px;
							}
							.txt-box{
								margin-top: 70px;
								.tit{
									@include font(20px);
								}
								.txt{
									@include font(14px);
								}
							}
						}
					}
				}
			}
			&.sec3{
				.tech-box{
					margin-top: 40px;
					.tech-graph{
						width: 500px;
						padding: 30px;
						.anim-box{
							.anim{
								&.step4{
									.hidden-box{
										.hidden-txt{
											@include font(11px);
										}
									}
								}
							}
						}
					}
					.txt-box{
						padding: 0 50px;
						.tit{
							@include font(22px, null, 32px);
						}
						.btn-round{
							margin-top: 30px;
						}
					}
				}
			}
			&.sec4{
				.solution-list{
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					gap: 40px;
					margin-top: 40px;
					& > li{
						.link-box{
							&::before{
								width: 80px;
								height: 80px;
							}
							&::after{
								width: 32px;
								height: 32px;
							}
							.txt-box{
								.tit{
									@include font(22px, null, 34px);
								}
								.info-list{
									& > li{
										@include font(14px, null, 22px);
										&::before{
											margin: -3px 8px;
											@include font(16px);
										}
									}
								}
							}
						}
					}
				}
			}
			&.sec5{
				.swiper-partner{
					.partner{
						.swiper-wrapper{
							.swiper-slide{
								.swiper-list{
									gap: 30px 50px;
									& > li{
										.img-set{
											.dim{
												.txt-set{
													padding: 0 5px;
													@include font(11px);
													.tit{
														@include font(14px);
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			&.sec6{
				.swiper-example{
					.example{
						.swiper-wrapper{
							.swiper-slide{
								.example-box{
									.img-set{
										width: 500px;
									}
									.txt-box{
										.tit{
											@include font(26px, null, 36px);
										}
										.info-box{
											.thumb{
												width: 50px;
												height: 50px;
											}
											.txt-set{
												.name-box{
													@include font(18px);
													& > div{
														& + div{
															&::before{
																height: 15px;
															}
														}
													}
												}
												.company{
													@include font(14px);
												}
											}
										}
									}
								}
							}
						}
					}
				}	
			}
		}
		.banner-area{
			height: 200px;
			.content{
				.banner-set{
					.tit{
						@include font(28px);
					}
				}
			}
		}
	}
	@media all and (max-width:1023px){
		section{
			&.sec1{
				padding: 40px 20px;
				.row-set{
					.img-box{
						width: 100%;
						margin-top: 30px;
					}
				}
			}
			&.sec2{
				padding: 60px 0 40px;
				.main-tit-set{
					padding: 0 20px;
				}
				.swiper-service{
					.service-list{
						& > li{
							padding: 40px;
							&::before{
								width: 48px;
								height: 48px;
							}
							.txt-box{
								margin-top: 70px;
								.tit{
									@include font(20px);
								}
								.txt{
									@include font(14px);
								}
							}
						}
					}
				}
			}
			&.sec3{
				.tech-box{
					display: block;
					margin-top: 30px;
					.tech-graph{
						width: 100%;
						padding: 20px;
						.anim-box{
							// min-height: 200px;
							.anim{
								&.step4{
									.hidden-box{
										&::after{
											content: '';
											@include center(self, 13%,0,null,null);
											width: 1px;
											height: 78%;
										}
										.hidden-txt{
											@include center(self, 13%,3px,null,null);
											display: inline-block;
											@include font(2vw);
											-webkit-transform:scale(0.8);
										}
									}
								}
							}
						}
					}
					.txt-box{
						padding: 40px 30px;
						.tit{
							@include font(18px,null,26px);
							word-break: keep-all;
						}
						.info-list{
							margin-top: 20px;
							& > li{
								&::before{
									margin: -2px 8px;
									@include font(16px);
								}
								@include font(14px,null,20px);
								word-break: keep-all;
							}
						}
						.btn-round{
							margin-top: 20px;
						}
					}
				}
			}
			&.sec4{
				.solution-list{
					grid-template-columns: repeat(1, 1fr);
					gap: 30px;
					margin-top: 30px;
					& > li{
						.link-box{
							&::before{
								width: 80px;
								height: 80px;
							}
							.txt-box{
								margin-top: 10px;
								.tit{
									@include font(18px,null,26px);
									word-break: keep-all;
									br{
										display: block;
									}
								}
								.info-list{
									margin-top: 10px;
									& > li{
										display: flex;
										@include font(14px,null,20px);
										word-break: keep-all;
										br{
											display: none;
										}
										&::before{
											margin: -2px 8px;
											@include font(16px);
										}
									}
								}
							}
						}
					}
				}
			}
			&.sec5{
				.swiper-partner{
					.partner{
						.swiper-wrapper{
							.swiper-slide{
								padding: 15px 5px;
								.swiper-list{
									display: grid;
									grid-template-columns: repeat(3,1fr);
									gap: 15px;
									& > li{
										.img-set{
											.dim{
												.txt-set{
													padding: 0 5px;
													@include font(10px);
													.tit{
														@include font(12px);
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			&.sec6{
				.swiper-example{
					.example{
						.swiper-wrapper{
							.swiper-slide{
								.example-box{
									display: block;
									border-radius: 20px;
									background: $bg-f7;
									.img-set{
										width: 100%;
									}
									.txt-box{
										width: 100%;
										padding: 30px 20px;
										.tit{
											@include font(18px,null,26px);
											word-break: keep-all;
										}
										.info-box{
											margin-top: 30px;
											.thumb{
												width: 40px;
												height: 40px;
												margin-right: 15px;
											}
											.txt-set{
												.name-box{
													@include font(14px);
													& > div{
														& + div{
															padding-left: 8px;
															&::before{
																content: '';
																width: 1px;
																height: 12px;
																margin-right: 8px;
															}
														}
													}
												}
												.company{
													@include font(12px);
												}
											}
										}
									}
								}
							}
						}
					}
				}	
			}
		}
		.banner-area{
			height: auto;
			padding: 40px 20px;
			.content{
				height: 100%;
				.banner-set{
					display: block;
					text-align: center;
					.tit{
						@include font(20px,null,28px);
						word-break: keep-all;
					}
					.btn-round.white{
						margin-top: 20px;
					}
				}
			}
		}
	}
}


/*******************************************************************
	introduce (소개)
********************************************************************/
.introduce{
	.top-section{
		padding: 80px 0;
		.tit{
			@include font(48px, $black-1, 60px);
			font-weight: $fw-bold;
		}
	}

	.tab-content{
		display: none;
		&.tab1{
			display: block;
			section{
				&.sec1{
					.row-set{
						.info-box{
							width: 50%;
						}
						.img-box{
							width: 560px;
						}
					}
				}
				&.sec2{
					.graph-box{
						position: relative;
						width: 100%;
						padding: 60px 80px;
						margin-top: 40px;
						border-radius: 20px;
						background: $white;
						box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15);
						.anim-box{
							position: relative;
							width: 100%;
							.anim{
								@include center(def);
								opacity: 0;
								width: 100%;
								height: 100%;
								svg{
									width: 100%;
									height: 100%;
									object-fit: contain;
								}
								&.def{
									position: static;
									opacity: 1;
								}
								&.step1,
								&.step2,
								&.step3{
									top: 5%;
								}
								&.dot1,
								&.dot2,
								&.dot3{
									z-index: 2;
									top: -5%;
								}
								&.step4{
									width: 100%;
									height: 100%;
									.hidden-box{
										overflow: hidden;
										@include center(def);
										width: 0;
										height: 100%;
										margin-left: 0;
										&::after{
											content: '';
											@include center(self, 15%,0,null,null);
											width: 1px;
											height: 75%;
											background: rgba(204, 204, 204, 0.4);
										}
										.hidden-txt{
											@include center(self, 18%,20px,null,null);
											@include font(13px, $primary);
											line-height: 1.2;
											font-weight: $fw-bold;
											text-align: right;
										}
									}
								}
							}
						}
					}
				}
				&.sec3{
					.row-set{
						.info-box{
							width: 45%;
						}
						.img-box{
							width: 570px;
							.anim-box{
								position: relative;
								width: 100%;
								.anim{
									@include center(def);
									opacity: 0;
									width: 100%;
									height: 100%;
									svg{
										width: 100%;
										height: 100%;
										object-fit: contain;
									}
									&.def{
										position: static;
										opacity: 1;
									}
								}
							}
						}
					}
				}
			}
		}
		&.tab2{
			section{
				.row-set{
					padding: 0 80px;
					.info-box{
						width: 45%;
					}
					.img-box{
						width: 390px;
					}
				}
				.swiper-ad{
					position: relative;
					width: 390px;
				}
				.info-list{
					margin-top: 30px;
					& > li{
						& + li{
							margin-top: 20px;
						}
						.txt{
							margin-top: 5px;
						}
					}
				}
				&.bg-type2{
					.img-box{
						order: 1;
					}
					.info-box{
						order: 0;
					}
				}	
			}
		}
		&.tab3{
			section{
				&.sec1{
					.row-set{
						.info-box{
							width: 40%;
							.s-tit{
								margin-top: 20px;
							}
						}
						.img-box{
							width: 560px;
						}
					}
				}
				&.sec2{
					.row-set{
						.total-set{
							display: flex;
							align-items: flex-end;
							justify-content: space-between;
							.num{
								@include font(200px, $black-1);
								line-height: 1;
								letter-spacing: -3px;
							}
							.unit-info{
								margin: 0 0 24px 10px;
								line-height: 1;
								.unit{
									@include font(48px, $black-1);
								}
								.cmt{
									margin-top: 5px;
									@include font(16px, $black-1);
								}
							}
						}
						.info-box{
							width: 560px;
							.tit-set{
								.s-tit{
									margin-top: 20px;
								}
							}
						}
					}
				}
			}
		}
		&.tab4{
			section{
				.img-box{
					margin-top: 40px;
					.info-txt{
						margin-top: 20px;
						@include font(14px, $black-9);
					}
				}
			}
		}	
	}
	
	@media all and (max-width:1200px){
		.top-section{
			padding: 50px 0;
			.tit{
				@include font(38px, null, 50px);
			}
		}
		.tab-content{
			&.tab1{
				section{
					&.sec1{
						.row-set{
							.img-box{
								width: 500px;
							}
						}
					}
					&.sec2{
						.graph-box{
							padding: 30px 40px;
						}
					}
					&.sec3{
						.row-set{
							.img-box{
								width: 480px;
							}
						}
					}
				}
			}
			&.tab2{
				section{
					.row-set{
						padding: 0 100px;
						.img-box{
							width: 350px;
						}
					}
					.swiper-ad{
						width: 350px;
					}
				}
			}
			&.tab3{
				section{
					&.sec1{
						.row-set{
							.info-box{
								width: 45%;
								.s-tit{
									margin-top: 30px;
								}
							}
							.img-box{
								width: 480px;
							}
						}
					}
					&.sec2{
						.row-set{
							.total-set{
								.num{
									@include font(160px);
								}
								.unit-info{
									margin: 0 0 24px 10px;
									.unit{
										@include font(38px);
									}
									.cmt{
										@include font(14px);
									}
								}
							}
							.info-box{
								width: 480px;
							}
						}
					}
				}
			}
		}
	}

	@media all and (max-width:1023px){
		.top-section{
			padding: 40px 20px;
			.tit{
				@include font(28px,null,36px);
				word-break: keep-all;
			}
		}
		.tab-content{
			&.tab1{
				section{
					&.sec1{
						.row-set{
							.info-box{
								width: 100%;
								.tit-set{
									.info-list{
										& > li{
											.txt{
												br{
													display: none;
												}
											}
										}
									}
								}
							}
							.img-box{
								width: 100%;
								margin-top: 30px;
							}
						}
					}
					&.sec2{
						.graph-box{
							padding: 15px 10px;
							margin-top: 30px;
							border-radius: 10px;
							.anim-box{
								.anim{
									&.step4{
										.hidden-box{
											margin-left: 0;
											&::after{
												background: rgba(204, 204, 204, 0.2);
											}
											.hidden-txt{
												@include center(self, 14%,5px,null,null);
												display: inline-block;
												@include font(2vw);
												-webkit-transform:scale(0.7);
											}
										}
									}
								}
							}
						}
					}
					&.sec3{
						.row-set{
							.info-box{
								width: 100%;
								.tit-set{
									.info-list{
										& > li{
											.txt{
												br{
													display: none;
												}
											}
										}
									}
								}
							}
							.img-box{
								width: 100%;
								margin-top: 30px;
							}
						}
					}
				}
			}
			&.tab2{
				section{
					padding-bottom: 20px;
					.row-set{
						display: flex;
						flex-direction: column;
						padding: 0;
						.info-box{
							width: 100%;
							margin-top: 10px;
						}
						.img-box{
							width: 100%;
						}
					}
					.swiper-ad{
						order: 1;
						width: 100%;
					}
					.info-list{
						order: 0;
						margin-top: 20px;
					}
					.img-box{
						order: 1;
					}
					.info-box{
						order: 0;
					}
				}
			}
			&.tab3{
				section{
					&.sec1{
						.row-set{
							.info-box{
								width: 100%;
								.s-tit{
									margin-top: 10px;
								}
							}
							.img-box{
								width: 100%;
								margin-top: 40px;
							}
						}
					}
					&.sec2{
						.row-set{
							display: flex;
							flex-direction: column;
							.total-set{
								order: 1;
								width: 100%;
								margin-top: 30px;
								.num{
									@include font(110px);
								}
								.unit-info{
									margin: 0 0 20px 10px;
									line-height: 1;
									.unit{
										@include font(24px);
									}
									.cmt{
										@include font(14px);
									}
								}
							}
							.info-box{
								order: 0;
								width: 100%;
								.tit-set{
									.s-tit{
										margin-top: 10px;
									}
								}
							}
						}
					}
				}
			}
			&.tab4{
				section{
					.img-box{
						margin-top: 30px;
						.info-txt{
							margin-top: 10px;
							@include font(12px);
						}
					}
				}
			}
		}
	}
}

/*******************************************************************
	consulting (상담하기)
********************************************************************/
.consulting{
	padding: 80px 0;
	.row-set{
		align-items: flex-start;
	}
	.form-box{
		width: 50%;
		.form-list{
			display: grid;
			grid-template-rows: repeat(1, 1fr);
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;
			& > li{
				.label{
					margin-bottom: 8px;
					@include font(16px, $black-1);
					&.necessary{
						display: flex;
						align-items: center;
						&::after{
							content: '*';
							flex-shrink: 0;
							margin-left: 5px;
							color: $primary;
						}
					}
				}
				&:nth-child(5) {
					grid-column: span 2;
				}	
			}
		}
		.term-box{
			margin-top: 40px;
			.tit{
				@include font(18px, $black-1);
				font-weight: $fw-bold;
				margin-bottom: 10px;
			}
			.txt-box{
				width: 100%;
				height: 200px;
				padding: 16px 20px;
				border-radius: 8px;
				background: $bg-f7;
				@include font(16px, $black-9, 24px);
				word-break: break-all;
				p{
					margin-bottom: 20px;
				}
				.txt-list{
					& > li{
						display: flex;
						word-break: keep-all;
						&::before{
							flex-shrink: 0;
							width: 20px;
						}
						&:nth-child(1){
							&::before{
								content: '1.';
							}
						}
						&:nth-child(2){
							&::before{
								content: '2.';
							}
						}
						&:nth-child(3){
							&::before{
								content: '3.';
							}
						}
						&:nth-child(4){
							&::before{
								content: '4.';
							}
						}
					}
				}
			}
		}
		.term-list{
			margin-top: 20px;
			& > li{
				display: flex;
				align-items: center;
				& + li{
					margin-top: 14px;
				}
				.chk-box{
					margin-right: 10px;
				}
				.txt{
					@include font(16px, $black-1);
					.link{
						color: $primary;
						padding-bottom: 1px;
						box-shadow: inset 0 -1px 0 $primary;
					}
				}
			}
		}
		.btn-round.primary{
			width: 100%;
			margin-top: 40px;
		}
	}
	
	@media all and (max-width:1200px){
		padding: 50px 0 40px;
		.form-box{
			width: 55%;
			.form-list{
				gap: 15px;
			}
		}
	}
	@media all and (max-width:1023px){
		padding: 40px 20px;
		.form-box{
			width: 100%;
			margin-top: 60px;
			.form-list{
				grid-template-rows: repeat(1, 1fr);
				grid-template-columns: repeat(1, 1fr);
				gap: 20px;
				& > li{
					.label{
						@include font(15px);
					}
					&:nth-child(5) {
						grid-column: span 1;
					}	
				}
			}
			.term-box{
				.tit{
					@include font(18px);
				}
				.txt-box{
					height: auto;
					@include font(14px, null, 22px);
					p{
						margin-bottom: 15px;
						word-break: keep-all;
					}
					.txt-list{
						& > li{
							br{
								display: none;
							}
						}
					}
				}
			}
		}
	}
}