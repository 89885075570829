/*******************************************************************
	Button
********************************************************************/
.btn{
	&-round{
		height: 60px;
		padding: 0 60px;
		border-radius: 30px;
		@include font(16px, $white);
		font-weight: $fw-bold;
		&.primary{
			background: $primary;
		}
		&.white{
			background: $white;
			color: $black-1;
		}
		&.disabled,
		&:disabled{
			background: $primary-disabled;
			color: $disabled;
		}

		// size
		&.md{
			height: 48px;
			padding: 0 40px;
		}
	}
	&-icon{
		&.close{
			width: 24px;
			height: 24px;
			background: url("https://dx6sd8rl35cx.cloudfront.net/assets/images/btn/btn_inp_close.svg") no-repeat 0 0;
			background-size: 100%;
		}
	}

	@media all and (max-width:1200px){
		&-round{
			height: 48px;
			padding: 0 50px;
			// size
			&.md{
				height: 36px;
				padding: 0 30px;
			}
		}
	}
	@media all and (max-width:1023px){
		&-round{
			padding: 0 40px;
			@include font(15px);
			// size
			&.md{
				padding: 0 20px;
			}
		}
	}
}