/*******************************************************************
	popup
********************************************************************/
.modal{
	z-index: 900;
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	opacity: 0.7;
	-ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=70)';
}
.popup{
	overflow: hidden;
	z-index: 902;
	display: none;
	position: fixed;
	top: 50%;
	left: 50%;
	width: 520px;
	padding: 40px;
	background: $white;
	border-radius: 20px;
	transform: translate(-50%, -50%);
	&-header{
		
	}
	&-content{
		
	}
	&-footer{
		
	}
}