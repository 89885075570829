// tab
.tab-area{
	z-index: 100;
	position: sticky;
	top: 80px;
	background: $white;
	.tab-wrap{
		display: flex;
		justify-content: space-between;
    width: 100%;
		padding: 20px 0;
		border-bottom: 1px solid $border-1;
		.tab-swiper{
			width: 100%;
			margin: 0;
			.swiper-wrapper{
				& > li{
					display: inline-flex;
					width: auto;
					cursor: pointer;
					&:hover,
					&.active{
						.tab{
							background: $primary;
							color: $white;
							font-weight: $fw-bold;
						}		
					}
					.tab{
						display: inline-flex;
						align-items: center;
						justify-content: center;
						width: 200px;
						height: 48px;
						border-radius: 24px;
						background: $bg-e7;
						@include font(16px, $black-9);
						transition: all 0.2s;
					}
				}
			}
		}
	}
	@media all and (max-width:1023px){
		top: 60px;
		.tab-wrap{
			padding: 15px 0;
			border-bottom: none;
			.tab-swiper{
				.swiper-wrapper{
					padding-left: 20px;
					& > li{
						&:first-child{
							margin-left: -20px;
						}
						&:hover{
							.tab{
								background: $bg-e7;
								color: $black-9;
								font-weight: inherit;
							}
						}
						&.active{
							.tab{
								background: $primary;
								color: $white;
								font-weight: $fw-bold;
							}		
						}
						.tab{
							width: auto;
							padding: 0 15px;
							height: 40px;
							@include font(14px);
						}
					}
				}
			}
		}
	}
}

// input
.inp{
	overflow: hidden;
	width: 100%;
	height: 48px;
	padding: 0 40px 0 15px;
	border-radius: 8px !important;
	border: 1px solid  $gray-c;
	@include font(16px, $black-1);
	&:focus{
		border-color: $primary;
		caret-color: $primary;
	}
	&:disabled{
		background: $bg-f7;
	}
	@media all and (max-width:1023px){
		height: 40px;
		padding: 0 40px 0 15px;
		border-radius: 6px !important;
		border: 1px solid  $gray-c;
		@include font(14px);
	}
}
.inp-set{
	position: relative;
	width: 100%;
	.btn-icon{
		display: none;
		&.close{
			@include center(vertical);
			right: 10px;
		}
	}
}

// textarea
.txt-area{
	overflow: hidden;
	width: 100%;
	height: 200px;
	padding: 15px;
	border: 1px solid  $gray-c;
	border-radius: 8px !important;
	@include font(16px, $black-1);
	&:focus{
		border-color: $primary;
		caret-color: $primary;
	}
	@media all and (max-width:1023px){
		border-radius: 6px !important;
		@include font(14px);
	}
}

// checkbox
.chk-box{
	input[type="checkbox"]{
		overflow: hidden;
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		clip: rect(0, 0, 0, 0);
		border: 0;
		&+label{
			cursor: pointer;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			&::before{
				content: '';
				display: block;
				width: 24px;
				height: 24px;
				border-radius: 4px;
				border: 1px solid $border-1;
				background: $white;
			}
		}
		&:checked + label{
			&::before{
				background: $primary  url("https://dx6sd8rl35cx.cloudfront.net/assets/images/icon/ico_chk.svg") no-repeat 50%;
				background-size: 16px auto;
				border-color: $primary;
			}
		}
	}
	@media all and (max-width:1023px){
		input[type="checkbox"]{
			&+label{
				&::before{
					width: 22px;
					height: 22px;
				}
			}
			&:checked + label{
				&::before{
					background-size: 14px auto;
				}
			}
		}	
	}
}

